import Image from 'next/image';
import React from 'react';

function WhyChooseUs() {
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <Image src="/images/why-choose.png" alt="image1" width={500} height={500} className="img-fluid" />
        </div>
        <div className="col-md-6 pt-md-4">
          <div className="font-sfpro">
            <h5 className="fs-50">Why Choose Us</h5>
            <p className="fs-23">
              No matter if it’s a new project or we are improving an existing one. We are always determined to build a
              scalable solution for our clients. We have already helped hundreds of entrepreneurs and businesses (both
              large and small enterprises) and here is how we have done it
            </p>
            <ol className="fs-23 ps-md-5">
              <li> Commitment to be on time</li>
              <li> Effective Partnership</li>
              <li> Transparent Communication</li>
              <li> Expert Consultation </li>
              <li> Results Oriented Solution</li>
            </ol>
            {/* 1.2.3.4.5. */}
          </div>
        </div>
      </div>
    </>
  );
}

export default WhyChooseUs;
